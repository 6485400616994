import { useQuery } from "react-query";

export const onlineUrl = 'https://www.astro.umd.edu/~msk/look-browser';
//export const localUrl = 'http://localhost:3000/~msk/look-browser';

export function baseUrl() {
  if (location.hostname === "localhost")
    return onlineUrl;
  else
    return onlineUrl;
}

async function fetchAllPhotometry() {
  console.log('fetch all target photometry');
  const response = await fetch(`${onlineUrl}/data/phot.json`)
  if (!response.ok) {
    console.log('Error fetching target photometry');
    throw new Error('Error fetching target photometry');
  }
  return response.json()
}

async function fetchBinnedPhotometry() {
  console.log('fetch binned target photometry');
  const response = await fetch(`${onlineUrl}/data/phot-binned.json`);
  if (!response.ok) {
    console.log('Error fetching binned target photometry', response);
    throw new Error('Error fetching binned target photometry');
  }
  return response.json()
}

async function fetchSummary() {
  console.log('fetch summary');
  const response = await fetch(`${onlineUrl}/data/summary.json`);
  if (!response.ok) {
    console.log('Error fetching binned target photometry');
    throw new Error('Error fetching binned target photometry');
  }
  return response.json()
}

async function fetchStackClusters() {
  console.log('fetch stack clusters');
  const response = await fetch(`${onlineUrl}/data/stack-clusters.json`);
  if (!response.ok) {
    console.log('Error fetching stack clusters');
    throw new Error('Error fetching stack clusters');
  }
  return response.json()
    .then((data) => {
      const clusters = {
        files: data,
        stacks: {}
      }
      Object.entries(data).forEach(([file, stacks]) => {
        const prefix = stacks[0].slice(0, -9);
        if (clusters.stacks[prefix]) {
          clusters.stacks[prefix].push(file);
        } else {
          clusters.stacks[prefix] = [file];
        }
      });
      return clusters;
    });
}

export function useSummary() {
  return useQuery('summary', fetchSummary, { staleTime: 1800000, retry: false });
}

export function usePhotometry() {
  return useQuery('phot', fetchAllPhotometry, { staleTime: 1800000, retry: false });
}

export function useBinnedPhotometry() {
  return useQuery('binned-phot', fetchBinnedPhotometry, { staleTime: 1800000, retry: false });
}


export function useStackClusters() {
  return useQuery('stack-clusters', fetchStackClusters, { staleTime: 1800000, retry: false });
}
