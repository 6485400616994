import React from 'react';
import { useNavigate } from 'react-router-dom';
import { sum } from 'd3-array';

import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { useSummary, useDataURLs } from '../services/firebase';
import { encodeParameter } from '../util';
import FooterButton from '../components/FooterButton';
import ObservationTable from '../components/ObservationTable';
import SelectTarget from '../components/SelectTarget';

function weightedMean(rows) {
  const weights = rows.map(row => row.merr5 ** -2);
  const sumWeights = sum(weights);
  return {
    value: sum(rows.map((row, index) => row.m5 * weights[index])) / sumWeights,
    err: sumWeights ** -0.5
  }
}

export default function Status({ phot, binnedPhot }) {
  const summary = useSummary();
  const dataURLs = useDataURLs();
  const { ntargets, nexposures, nobs } = summary.isSuccess ? summary.data : {};

  const twoDaysAgo = (new Date(new Date().getTime() - 172800000))
    .toISOString().replace('T', ' ');
  const recentPhot = [...binnedPhot.data]
    .sort((a, b) => b.date.localeCompare(a.date))
    .filter(row => row.date > twoDaysAgo);

  const recentTargets = Array.from(
    new Set(recentPhot.map(row => row.target))
  );
  const recentTargetsSummary = recentTargets
    .map(target => recentPhot.filter(row => row.target === target))
    .map(obs => ({
      target: obs[0].target,
      nobs: obs.length,
      g: weightedMean(obs.filter(row => row['catalog filter'] === 'g')),
      r: weightedMean(obs.filter(row => row['catalog filter'] === 'r')),
    }))
    .sort((a, b) => a.target.localeCompare(b.target, undefined, { numeric: true, sensitivity: "base" }));

  const navigate = useNavigate();
  const openTarget = (target) => navigate(`/target/${encodeParameter(target)}`);

  React.useEffect(() => {
    document.title = "Quick LOOK Browser";
  });

  return (
    <>
      <SelectTarget phot={phot} />
      <List>
        <ListItem>
          <ListItemText
            primary={ntargets || "..."}
            secondary="Targets"
          />
          <ListItemText
            primary={nexposures || "..."}
            secondary="Calibrated exposures"
          />
          <ListItemText
            primary={nobs || "..."}
            secondary="Photometric epochs"
          />
        </ListItem>
      </List>
      <Typography variant="h6" gutterBottom>Recently observed targets</Typography>
      {recentTargetsSummary.length
        ?
        <List dense>
          {recentTargetsSummary.map(summary =>
            <ListItem key={summary.target}>
              <ListItemButton onClick={() => openTarget(summary.target)}>
                <ListItemText
                  primary={summary.target}
                  secondary={[
                    `${summary.nobs} observations`,
                    ...[['g', summary.g], ['r', summary.r]]
                      .filter(([label, m]) => isFinite(m.value))
                      .map(([label, m]) => `${label}=${m.value.toFixed(2)}±${m.err.toFixed(2)}`)
                  ].join(', ')}
                />
              </ListItemButton>
            </ListItem>
          )}
        </List>
        : <Alert severity="info">No observations in the last 2 days.</Alert>
      }
      <FooterButton
        title="All binned observations"
        color="primary"
        download={dataURLs.binnedPhot}
      >
        <ObservationTable rows={binnedPhot.data} />
      </FooterButton>
      <FooterButton
        title="All observations"
        download={dataURLs.phot}
        sx={{ right: 92 }}
      >
        <ObservationTable rows={phot.data} />
      </FooterButton>
      <FooterButton
        title="Recent observations"
        icon={<AccessTimeIcon />}
        sx={{ right: 168 }}
      >
        {recentPhot.length
          ? <ObservationTable rows={recentPhot} />
          : <Alert severity="info">No observations in the last 2 days.</Alert>
        }
      </FooterButton>
    </>
  );
}
