import React from 'react';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import { onlineUrl } from '../services/look';
import legend from '../media/legend.png';

const temporalFilterImage = (stack) =>
  `${onlineUrl}/data/${stack["stack prefix"].replace('stack', 'plot')}_med_tf.jpg`;

export default function StackViewer({ stackIndex, stacks, ...props }) {
  const [update, setUpdate] = React.useState(0);
  const [showLegend, setShowLegend] = React.useState(false);
  const [srcs, setSrcs] = React.useState([]);
  const counter = React.useRef(0);

  React.useEffect(() => {
    counter.current = 0;
    setSrcs(stacks.map(temporalFilterImage));
  }, [stacks]);

  return (
    <Container {...props}>
      {srcs.map((src, i) => (
        <img
          key={src}
          alt="stack analysis"
          onLoad={() => {
            // must use counter to track image count, or else some image updates
            // will be skipped
            counter.current += 1;
            setUpdate(update + 1);
          }}
          src={src}
          style={{
            display: (i !== stackIndex) && 'none',
            // maxHeight: '75vh',
            width: '100%'
          }}
        />
      ))}
      <LinearProgress variant="determinate" value={counter.current / srcs.length * 100} />
      <Typography variant="body2">
        Use left/right arrows to navigate stacks.
        <Button onClick={() => setShowLegend(!showLegend)} size="small" variant="text">Toggle legend.</Button>
      </Typography>
      <img
        src={legend}
        style={{
          display: !showLegend && 'none',
          maxHeight: '75vh',
          maxWidth: '100%'
        }}
      />
    </Container>
  );
}