import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from "@mui/material/Grid";
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';

import FooterButton from '../components/FooterButton';
import Lightcurve from '../components/Lightcurve';
import ObservationTable from '../components/ObservationTable';
import SelectTarget from '../components/SelectTarget';
import StackHeader from '../components/StackHeader';
import StackMetadata from '../components/StackMetadata';
import StackNavigation from '../components/StackNavigation';
import StackViewer from '../components/StackViewer';

import { decodeParameter, useDownloadableTable, stackNavigator } from '../util';
import { useLayout, useStackIndex } from '../services/cookies';

export default function Target({ phot, binnedPhot, stackClusters }) {
  const [layout, setLayout] = useLayout();
  const params = useParams();
  const target = decodeParameter(params.target);
  const [stackIndex, setStackIndex] = useStackIndex(target);
  const [targetPhot, setTargetPhot] = React.useState([]);
  const [stacks, setStacks] = React.useState([]);
  const [selectedStackIndices, setSelectedStackIndices] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const targetPhotDataURL = useDownloadableTable(targetPhot);
  const stackDataURL = useDownloadableTable(stacks);

  const stack = stacks.length ? stacks[stackIndex] : null;

  const stackNavigation = stackNavigator(stacks, stackIndex, setStackIndex, selectedStackIndices);

  React.useEffect(() => {
    const basename = searchParams.get("stack");
    // if a specific stack is requested, we cannot display it until the stacks
    // are loaded
    if (basename && (stacks.length > 0)) {
      stackNavigation.viewByStackBasename(basename);
      setSearchParams({});
    }
  }, [searchParams, stacks]);

  React.useEffect(() => {
    setTargetPhot(phot.data
      .filter(row => row.target === target)
      .sort((a, b) => a.date.localeCompare(b.date)));
  }, [phot]);

  React.useEffect(() => {
    // Set up stacks array
    // phot.data has all stacks, binnedPhot.data only has those with good photometry
    // we want the stacks array to contain all stack images
    const files = phot.data
      .filter((row) => row.target === target)
      .map((row) => row["file"]);

    const allStackPrefixes = new Set(
      files
        .filter((file) => stackClusters.data.files[file])
        .map((file) => stackClusters.data.files[file][0].slice(0, -9))
    );

    const allStacks = Array.from(allStackPrefixes)
      .map((prefix) => {
        const binned = binnedPhot.data.find((row) => row["stack prefix"] === prefix);
        if (binned) {
          // this had good photometry
          return binned;
        } else {
          // otherwise, just get the metadata from the first phot row
          const files = stackClusters.data.stacks[prefix];
          const single = {
            ...phot.data.find((row) => row["file"] === files[0]),
            "stack prefix": prefix,
            source: files[0].split('/')[2].split('-')[0],
            "N exp": files.length
          };
          // but remove the photometry
          return Object.fromEntries(
            Object.entries(single)
              .filter(([key, value]) => !key.startsWith('m'))
          );
        }
      });

    const stacks = allStacks
      .sort((a, b) => a.date.localeCompare(b.date))
      .map((row, index) => ({
        // annotate with file basename and stack index
        basename: row['stack prefix'].split('/')[2],
        index: index,
        ...row
      }));

    setStacks(stacks);
  }, [phot, binnedPhot, stackClusters]);

  if ((selectedStackIndices.length > 0) && (!selectedStackIndices.includes(stackIndex))) {
    stackNavigation.viewByStackIndex(selectedStackIndices[0]);
  }

  React.useEffect(() => {
    document.title = "Quick LOOK Browser : " + (target || "Target");
  }, [target]);

  const changeLayout = (event) => {
    event.target.checked ? setLayout("horizontal") : setLayout("vertical");
  };
  const itemSizes = (layout === "horizontal") ? { xs: 12, md: 6 } : { xs: 12 };

  return (
    <>
      <Box sx={{ display: 'flex', mb: 4 }}>
        <SelectTarget
          target={target}
          phot={phot}
          sx={{ display: 'flex', flexGrow: 1 }}
        />
        <FormControlLabel
          control={<Switch checked={layout === "horizontal"} onChange={changeLayout} />}
          label="Horizontal layout"
          sx={{ display: 'flex' }}
        />
      </Box>
      {target && stacks && stack &&
        <Grid container>
          <Grid item {...itemSizes}>
            <Lightcurve
              target={target}
              targetPhot={targetPhot}
              stacks={stacks}
              stackIndex={stackIndex}
              stackNavigation={stackNavigation}
              stackClusters={stackClusters}
              selectedStackIndices={selectedStackIndices}
              setSelectedStackIndices={setSelectedStackIndices}
            />
          </Grid>
          <Grid item {...itemSizes}>
            <StackHeader target={target} stack={stack} align="center" sx={{ mb: 1 }} />
            <StackViewer
              stackIndex={stackIndex}
              stacks={stacks}
              align="center"
            />
            <StackNavigation
              stackIndex={stackIndex}
              selectedStackIndices={selectedStackIndices}
              stacks={stacks}
              stackNavigation={stackNavigation}
              align="center"
            />
            <hr />
            <StackMetadata stack={stack} />
          </Grid>
        </Grid>
      }
      <Stack spacing={2}>
        {targetPhot.length == 0 &&
          <Alert severity="info">No photometry for this comet.</Alert>
        }
        {stacks.length == 0 &&
          <Alert severity="info">No stacked data for this comet.</Alert>
        }
      </Stack>
      <FooterButton title="Target binned photometry" color="primary" download={stackDataURL}>
        <ObservationTable rows={stacks} />
      </FooterButton>
      <FooterButton title="Target photometry" sx={{ right: 92 }} download={targetPhotDataURL}>
        <ObservationTable rows={targetPhot} />
      </FooterButton>
    </>
  );
}