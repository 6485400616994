import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';

import { encodeParameter } from '../util';
import Table from './Table';

// target   source                date fracday      tmtp     rh  delta  phase
// pixel scale filter catalog filter N exp exptime airmass seeing rho10k     m2     m5    
// m10    m12    m20    m5k   m10k   m20k merr2 merr5 merr10 merr12 merr20 
// merr5k merr10k merr20k ostat
const binnedPhotometryColumns = [
  { field: 'target', label: 'Target', width: 150 },
  { field: 'source', label: 'Source', width: 100 },
  { field: 'date', label: 'Date (UTC)', width: 200, date: true },
  { field: 'tmtp', label: 'T-Tp (days)', width: 100, numeric: true },
  { field: 'rh', label: 'rh (au)', width: 100, numeric: true },
  { field: 'delta', label: 'Delta (au)', width: 100, numeric: true },
  { field: 'phase', label: 'Phase (deg)', width: 100, numeric: true },
  { field: 'pixel scale', label: 'Pixel scale (")', width: 50, numeric: true },
  { field: 'filter', label: 'Filter', width: 50 },
  { field: 'catalog filter', label: 'Cat. Filter', width: 50 },
  { field: 'N exp', label: 'N exp.', width: 50, numeric: true },
  { field: 'exptime', label: 't exp. (s)', width: 75, numeric: true },
  { field: 'airmass', label: 'Airmass', width: 75, numeric: true },
  { field: 'seeing', label: 'Seeing (")', width: 75, numeric: true },
  { field: 'rho10k', label: 'ρ 10k (")', width: 75, numeric: true },
  { field: 'm2', label: 'm(2") (mag)', width: 75, numeric: true },
  { field: 'merr2', label: 'unc (mag)', width: 75, numeric: true },
  { field: 'm5', label: 'm(5") (mag)', width: 75, numeric: true },
  { field: 'merr5', label: 'unc (mag)', width: 75, numeric: true },
  { field: 'm10', label: 'm(10") (mag)', width: 75, numeric: true },
  { field: 'merr10', label: 'unc (mag)', width: 75, numeric: true },
  { field: 'm20', label: 'm(20") (mag)', width: 75, numeric: true },
  { field: 'merr20', label: 'unc (mag)', width: 75, numeric: true },
  { field: 'm5k', label: 'm(5k) (mag)', width: 75, numeric: true },
  { field: 'merr5k', label: 'unc (mag)', width: 75, numeric: true },
  { field: 'm10k', label: 'm(10k) (mag)', width: 75, numeric: true },
  { field: 'merr10k', label: 'unc (mag)', width: 75, numeric: true },
  { field: 'm20k', label: 'm(20k) (mag)', width: 75, numeric: true },
  { field: 'merr20k', label: 'unc (mag)', width: 75, numeric: true },
];

/*
file       target                date fracday      tmtp
ra                 dec     rh  delta   phase pixel scale filter catalog filter N cal
zp color cor zp err exptime airmass seeing rho10k     cx     cy
dc   bgarea bg  bgsig       flux2       flux5      flux10      flux12
flux20      flux5k     flux10k     flux20k     m2     m5    m10    m12
m20    m5k   m10k   m20k    merr2     merr5 merr10  merr12  merr20  merr5k
merr10k merr20k
*/
const photometryColumns = [
  { field: 'file', label: 'File', width: 275, format: (s) => s.split('/')[2].slice(0, -8) },
  { field: 'target', label: 'Target', width: 150 },
  { field: 'date', label: 'Date (UTC)', width: 200, date: true },
  { field: 'tmtp', label: 'T-Tp (days)', width: 100, numeric: true },
  { field: 'ra', label: 'RA (deg)', width: 100, numeric: true },
  { field: 'dec', label: 'Dec (deg)', width: 100, numeric: true },
  { field: 'rh', label: 'rh (au)', width: 100, numeric: true },
  { field: 'delta', label: 'Delta (au)', width: 100, numeric: true },
  { field: 'phase', label: 'Phase (deg)', width: 100, numeric: true },
  { field: 'pixel scale', label: 'Pixel scale (")', width: 50, numeric: true },
  { field: 'filter', label: 'Filter', width: 50 },
  { field: 'catalog filter', label: 'Cat. Filter', width: 50 },
  { field: 'N cal', label: 'N cal.', width: 50, numeric: true },
  { field: 'zp', label: 'ZP (mag)', width: 75, numeric: true },
  { field: 'color cor', label: 'Clr. cor.', width: 75, numeric: true },
  { field: 'zp err', label: 'ZP unc', width: 50, numeric: true },
  { field: 'exptime', label: 't exp. (s)', width: 75, numeric: true },
  { field: 'airmass', label: 'Airmass', width: 75, numeric: true },
  { field: 'seeing', label: 'Seeing (")', width: 75, numeric: true },
  { field: 'rho10k', label: 'ρ 10k (")', width: 75, numeric: true },
  { field: 'cx', label: 'x', width: 50, numeric: true },
  { field: 'cy', label: 'y', width: 50, numeric: true },
  { field: 'dc', label: 'Eph. offset (pix)', width: 50, numeric: true },
  { field: 'bgsig', label: 'Bg unc (DN/pix)', width: 50, numeric: true },
  { field: 'flux5', label: 'Flux(5") (DN)', width: 50, numeric: true },
  { field: 'm2', label: 'm(2") (mag)', width: 75, numeric: true },
  { field: 'merr2', label: 'unc (mag)', width: 75, numeric: true },
  { field: 'm5', label: 'm(5") (mag)', width: 75, numeric: true },
  { field: 'merr5', label: 'unc (mag)', width: 75, numeric: true },
  { field: 'm10', label: 'm(10") (mag)', width: 75, numeric: true },
  { field: 'merr10', label: 'unc (mag)', width: 75, numeric: true },
  { field: 'm20', label: 'm(20") (mag)', width: 75, numeric: true },
  { field: 'merr20', label: 'unc (mag)', width: 75, numeric: true },
  { field: 'm5k', label: 'm(5k) (mag)', width: 75, numeric: true },
  { field: 'merr5k', label: 'unc (mag)', width: 75, numeric: true },
  { field: 'm10k', label: 'm(10k) (mag)', width: 75, numeric: true },
  { field: 'merr10k', label: 'unc (mag)', width: 75, numeric: true },
  { field: 'm20k', label: 'm(20k) (mag)', width: 75, numeric: true },
  { field: 'merr20k', label: 'unc (mag)', width: 75, numeric: true },
];

export default function ObservationTable({ rows }) {
  const navigate = useNavigate();
  const binned = rows && rows.length && rows[0].source !== undefined;
  const columns = binned ? binnedPhotometryColumns : photometryColumns;
  columns[columns.findIndex(column => column.field === "target")].format = (target) => (
    <Button onClick={() => navigate(`/target/${encodeParameter(target)}`)}>
      {target}
    </Button>
  );

  return (
    <Table
      columns={columns}
      rows={rows || []}
    />
  );
}
