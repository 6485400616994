import React from "react";
import * as firebase from "firebase/app";
import { getDatabase, connectDatabaseEmulator, ref as databaseRef } from 'firebase/database';
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getBlob, getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import { useObjectVal } from 'react-firebase-hooks/database';
import { loadAsync } from "jszip";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const database = getDatabase(firebaseApp);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
setPersistence(auth, browserLocalPersistence);

if (location.hostname === "localhost") {
  // Point to the RTDB emulator running on localhost.
  connectDatabaseEmulator(database, "localhost", 9000);
}

export function fromFirebase(key, useFunction) {
  const [data, loading, error] = useFunction(databaseRef(database, key));
  return {
    data: data,
    isLoading: loading,
    error: error,
    isSuccess: !loading && !error
  };
}

export function useUsers() {
  return fromFirebase('users', useObjectVal);
}

function useStorage(path, process) {
  // assumes file is zipped JSON formatted text
  const [data, setData] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const ref = storageRef(storage, path);
    setLoading(true);
    getBlob(ref)
      .then(loadAsync)
      .then((zip) => {
        const filename = path.slice(path.lastIndexOf('/') + 1, -4);
        console.log("fetch", filename);
        const file = zip.file(filename);
        if (!file)
          throw new Error(`Error retrieving ${filename} from ${path}.`);
        return file.async("string");
      })
      .then((s) => JSON.parse(s))
      .then((data) => process ? process(data) : data)
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);  // empty so that it only runs once

  return {
    data,
    isLoading,
    isError: Boolean(error),
    error,
    isSuccess: !isLoading && !Boolean(error)
  };
}

export function usePhotometry() {
  return useStorage('data/phot.json.zip');
}

export function useBinnedPhotometry() {
  return useStorage('data/phot-binned.json.zip');
}

export function useSummary() {
  return useStorage('data/summary.json.zip');
}

export function useStackClusters() {
  return useStorage('data/stack-clusters.json.zip', parseStackClusters);
}

const parseStackClusters = (data) => {
  const clusters = {
    files: data,
    stacks: {}
  }
  Object.entries(data).forEach(([file, stacks]) => {
    const prefix = stacks[0].slice(0, -9);
    if (clusters.stacks[prefix]) {
      clusters.stacks[prefix].push(file);
    } else {
      clusters.stacks[prefix] = [file];
    }
  });
  return clusters;
}

export function useDataURLs() {
  const [phot, setPhot] = React.useState(null);
  const [binnedPhot, setBinnedPhot] = React.useState(null);
  React.useEffect(() => {
    getDownloadURL(storageRef(storage, "data/phot.txt.zip"))
      .then(setPhot);
    getDownloadURL(storageRef(storage, "data/phot-binned.txt.zip"))
      .then(setBinnedPhot);
  });
  return { phot, binnedPhot };
}

export default firebaseApp;