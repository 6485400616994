import React from 'react';

import { Routes, Route, useSearchParams, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';

import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { encodeParameter } from '../util';
import { auth, useUsers, usePhotometry, useBinnedPhotometry, useStackClusters } from '../services/firebase';

import Avatar from './Avatar';
import Link from './Link';
import Status from '../pages/Status';
import Target from '../pages/Target';

function MenuLink(props) {
  return <Link color="inherit" sx={{
    textDecoration: 'none',
  }} {...props} />
}

export default function Main({ user }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const users = useUsers();
  const phot = usePhotometry();
  const binnedPhot = useBinnedPhotometry();
  const stackClusters = useStackClusters();

  const target = searchParams.get("target");
  const navigate = useNavigate();

  if (target) {
    const path = `/target/${encodeParameter(target)}`;
    const prefix = searchParams.get("stack");
    if (prefix)
      navigate(`${path}?stack=${prefix}`);
    else
      navigate(path);
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const pages = [
    { to: "/", label: "Status" },
    { to: "/target", label: "Target" },
  ];

  return (
    <>
      {users.error && <Alert severity="error">{users.error.toString()}</Alert>}
      {users.isSuccess &&
        <Box>
          <AppBar position="static" color="primary" elevation={1}>
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
              >
                Quick LOOK Browser
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center"><MenuLink to={page.to}>{page.label}</MenuLink></Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
              >
                Quick LOOK Browser
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                  <Button
                    key={page.label}
                    onClick={handleCloseNavMenu}
                    sx={{ ml: 4, color: 'inherit', display: 'block' }}
                    component={Link}
                    to={page.to}
                  >
                    {page.label}
                  </Button>
                ))}
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar user={user} users={users} />
                </IconButton>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={() => signOut(auth)}>Sign out</MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>
          <Box sx={{ m: 2, mb: '100px' }}>
            {(phot.isLoading || binnedPhot.isLoading) && (
              <Box>
                <CircularProgress />
                <Typography variant="body2">Loading photometry...</Typography>
              </Box>
            )}
            {(phot.isError || binnedPhot.isError || stackClusters.isError) && <Alert severity="error">Error loading data sources</Alert>}
            {phot.isSuccess && binnedPhot.isSuccess && stackClusters.isSuccess &&
              <Routes>
                <Route path="/" element={<Status {...{ phot, binnedPhot }} />} />
                <Route path="/target" element={<Target {...{ phot, binnedPhot, stackClusters }} />} />
                <Route path="/target/:target" element={<Target {...{ phot, binnedPhot, stackClusters }} />} />
              </Routes>
            }
          </Box>
        </Box>
      }
    </>
  );
}

