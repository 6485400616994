import React from 'react';
import { useNavigate } from 'react-router-dom';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { encodeParameter } from '../util';

export default function AllTargetsSelect({ phot, target, sx }) {
  const targets = Array.from(new Set(phot.data.map(row => row.target)))
    .sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }));

  const getRandomTarget = () => {
    const pick = Math.floor(Math.random() * targets.length);
    return targets[pick];
  };

  const navigate = useNavigate();
  const openTarget = (newTarget) => {
    navigate(`/target/${encodeParameter(newTarget)}`);
  };

  return (
    <Stack sx={{ ...sx }} spacing={2} direction="row">
      <FormControl sx={{ width: '15em' }}>
        <Autocomplete
          id="target-field"
          options={targets}
          renderInput={(params) => <TextField {...params} label="Target" fullWidth={true} />}
          onChange={(event, value) => { openTarget(value) }}
          value={target}
          loading={phot.isLoading}
        />
      </FormControl>
      <Button color="primary" onClick={() => openTarget(getRandomTarget())}>Random target</Button>
    </Stack>
  );
}
