import React from 'react';
import { VariableSizeGrid as Grid } from 'react-window';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

function getColumnSorter(column, order) {
  if (column === null) {
    return (a, b) => 0;
  } else {
    return (a, b) => {
      let A = a[column.field];
      let B = b[column.field];

      const sign = order === 'desc' ? -1 : 1;

      if (A === B)
        return 0;

      // nulls will always be last
      if (A === null)
        return sign;

      if (B === null)
        return -sign;

      if (column.numeric) {
        A = Number(A);
        B = Number(B);
      } else if (column.date) {
        A = new Date(A);
        B = new Date(B);
      } else {
        // ignore case
        A = String(A).toLowerCase().trim();
        B = String(B).toLowerCase().trim();

        ['the ', 'a ', '('].forEach(prefix => {
          A = A.startsWith(prefix) ? A.slice(prefix.length) : A;
          B = B.startsWith(prefix) ? B.slice(prefix.length) : B;
        });

        return sign * A.localeCompare(B, undefined, { numeric: true, sensitivity: "base" });
      }

      if (A < B) {
        return -sign;
      }
      if (A > B) {
        return sign;
      }

      // equal
      return 0;
    }
  }
}

const HeaderCell = ({ columnIndex, rowIndex, style, data }) => (
  <div style={{ ...style, borderBottom: '1px solid #aaa' }}>
    <TableCell variant="head" sx={{ border: "none" }} padding="none" align="center" component="div">
      {data[rowIndex][columnIndex]}
    </TableCell>
  </div >
);

const DataCell = ({ columnIndex, rowIndex, style, data }) => (
  <div style={{ ...style, borderBottom: '1px solid #aaa' }}>
    <TableCell sx={{ border: "none" }} padding="none" component="div">
      {data[rowIndex][columnIndex]}
    </TableCell>
  </div>
);

const Cell = (props) => {
  return (
    (props.rowIndex === 0) ? <HeaderCell {...props} /> : <DataCell {...props} />
  )
};

function rowContains(row, searchStrings) {
  if (searchStrings.length === 0) {
    return true;
  }
  const rowString = row.join(" ").toLowerCase();
  const search = searchStrings.map(s => rowString.includes(s)).filter(test => test);
  return search.length == searchStrings.length;
}

export default function Table({ columns, rows }) {
  const ref = React.useRef(null);
  const [width, setWidth] = React.useState(1000);
  const [height, setHeight] = React.useState(500);
  const [searchStrings, setSearchStrings] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState(null);
  const [order, setOrder] = React.useState('asc');

  const search = (event) => {
    setSearchStrings(event.target.value.split(/\s+/));
  }

  const handleRequestSort = (column) => {
    const isAsc = column === orderBy && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
  };

  const itemData = [
    columns.map(column =>
      // decorate column headings with sort annotations
      <TableSortLabel
        active={orderBy === column}
        direction={orderBy === column ? order : 'asc'}
        onClick={() => handleRequestSort(column)}
      >
        {column.label}
        {orderBy === column ? (
          <Box component="span" sx={visuallyHidden}>
            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </Box>
        ) : null}
      </TableSortLabel>
    ),
    ...rows
      .sort(getColumnSorter(orderBy, order))
      .map(row => columns.map(column => row[column.field]))
      .filter(row => rowContains(row, searchStrings))
      .map(row => row.map((cell, index) => columns[index].format ? columns[index].format(cell, row) : cell))
  ];

  const handleWindowResize = () => {
    setWidth(ref.current.offsetWidth);
    setHeight(window.innerHeight - 175);
  }

  React.useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <Box>
      <TextField
        label="Search"
        variant="outlined"
        onChange={search}
        sx={{ my: 2 }}
        helperText="Matches unformatted data columns, case insensitive"
      />

      <div ref={ref}>
        <Grid
          columnCount={columns.length}
          columnWidth={index => columns[index].width}
          height={height}
          rowCount={itemData.length}
          rowHeight={index => (index === 0 ? 70 : 35)}
          width={width}
          itemData={itemData}
        >
          {Cell}
        </Grid>
      </div>
    </Box>
  );
}