import React from 'react';

import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';

import { onlineUrl } from '../services/look';
import { mpcFormat } from '../util';

import Link from './Link';

function updateClipboard(newClip) {
  navigator.clipboard.writeText(newClip).then(function () {
    /* clipboard successfully set */
  }, function () {
    /* clipboard write failed */
  });
}

function MPCheckerButton({ stack }) {
  return (
    <form name="mpchecker-form" method="POST" action="https://minorplanetcenter.net/cgi-bin/mpcheck.cgi" target="_blank" style={{ display: "inline" }}>
      <input type="hidden" name="year" value={stack.date.substring(0, 4)} />
      <input type="hidden" name="month" value={stack.date.substring(5, 7)} />
      <input type="hidden" name="day" value={`${stack.date.substring(8, 10)}.${stack.fracday.toFixed(5).substring(2)}`} />
      <input type="hidden" name="which" value="pos" />
      <input type="hidden" name="ra" value={mpcFormat.ra(stack.ra)} />
      <input type="hidden" name="decl" value={mpcFormat.dec(stack.dec)} />
      <input type="hidden" name="TextArea" value="" />
      <input type="hidden" name="radius" value="2" />
      <input type="hidden" name="limit" value="24.0" />
      <input type="hidden" name="oc" value="I41" />
      <input type="hidden" name="sort" value="d" />
      <input type="hidden" name="mot" value="h" />
      <input type="hidden" name="tmot" value="s" />
      <input type="hidden" name="pdes" value="u" />
      <input type="hidden" name="needed" value="f" />
      <input type="hidden" name="ps" value="n" />
      <input type="hidden" name="type" value="p" />
      <Button color="primary" variant="contained" disableElevation sx={{ mr: 2 }} size="small" onClick={() => document.forms['mpchecker-form'].submit()}>MPChecker</Button>
    </form>
  );
}

const temporalFilterFits = (stack) =>
  `${onlineUrl}/data/${stack["stack prefix"].replace('stacks', 'processed')}_med_tf.fits`;
const stackFits = (stack) =>
  `${onlineUrl}/data/${stack["stack prefix"]}_med.fits`;

export default function StackHeader({ target, stack, ...props }) {
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [snack, setSnack] = React.useState(null);
  const openMenu = Boolean(menuAnchor);

  stack.basename = stack['stack prefix'].split('/')[2];

  const openMenuCallback = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchor(null);
  };
  const pos = stack && `${stack.ra}${(stack.dec >= 0) ? '+' : ''}${stack.dec}`;

  const copyLinkToClipboard = () => {
    const url = `${document.URL}?stack=${stack.basename}`;
    navigator.clipboard.writeText(url)
      .then(() => {
        setSnack({ severity: "success", content: "Link to this stack copied to clipboard" });
      }, function () {
        setSnack({ severity: "info", content: "Link to this stack:" + url });
      });
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnack(null);
  };

  return (
    <Container {...props}>
      <Button color="primary" variant="contained" disableElevation sx={{ mr: 2 }} size="small" component="a" href={`https://ssd.jpl.nasa.gov/sbdb.cgi?sstr=${target}`} target="_blank">SBDB</Button>
      <Button color="primary" variant="contained" disableElevation sx={{ mr: 2 }} size="small" component="a" href={`http://www.minorplanetcenter.net/db_search/show_object?object_id=${target}`} target="_blank">MPC</Button>
      <MPCheckerButton stack={stack} />
      <Button color="primary" variant="contained" disableElevation sx={{ mr: 2 }} size="small" component="a" href={`http://ps1images.stsci.edu/cgi-bin/ps1cutouts?pos=${pos}&filter=g&filter=r&filter=i&filetypes=stack&auxiliary=data&size=720&output_size=0&verbose=0&autoscale=99.500000`} target="_blank">PS1</Button>
      <Tooltip title="Copy URL to this stack">
        <Chip
          label={<b>{stack.basename}</b>}
          color="primary"
          variant="outlined"
          onClick={copyLinkToClipboard}
          sx={{ mr: 2 }}
        />
      </Tooltip>
      <Button
        aria-controls={openMenu ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        variant="text"
        onClick={openMenuCallback}
      >
        Download...
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={menuAnchor}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose} component="a" href={stackFits(stack)}>Full stack image</MenuItem>
        <MenuItem onClick={handleClose} component="a" href={temporalFilterFits(stack)}>Temporally filtered</MenuItem>
      </Menu>
      <Snackbar open={Boolean(snack)} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={snack ? snack.severity : "success"} sx={{ width: '100%' }}>
          {snack ? snack.content : ""}
        </Alert>
      </Snackbar>
    </Container >
  );
}