import React from 'react';

export function encodeParameter(parameter) {
  if (parameter) {
    return parameter.replace('/', '_');
  } else {
    return "";
  }
}

export function decodeParameter(parameter) {
  if (parameter) {
    return parameter.replace('_', '/');
  } else {
    return null;
  }
}

export function phaseSchleicherMarcus(th) {
  // th in degrees, Phi in mag
  return -[3.00977231e-15, 4.77914601e-13, -4.19820551e-10, 6.91889188e-08,
    -5.40348305e-06, 6.26806666e-04, -4.75261424e-02, 6.55815711e-03].map(
      (c, i) => c * th ** (7 - i)
    ).reduce((sum, cur) => (sum + cur));
}

export const cleanPhotometry = (rows, config) =>
  rows.filter(row => (
    (row[`m${config.aperture}`] > 0)
    && isFinite(row[`m${config.aperture}`])
    && (row[`merr${config.aperture}`] <= 0.36)
  ));

/**
 * Formatting functions for MPChecker
 */
export const mpcFormat =
{
  /**
    * @param {float} ra decimal degrees
    */
  ra: (angle) => {
    const hours = angle / 15;
    let h = Math.floor(Math.abs(hours));
    let m = Math.floor((Math.abs(hours) - h) * 60);
    let s = ((Math.abs(hours) - h) * 60 - m) * 60;

    if (s >= 60) {
      s -= 60;
      m += 1;
    }

    if (m >= 60) {
      m -= 60;
      h += 1;
    }

    if (h >= 24) {
      h -= 24;
    }

    h = h.toFixed(0);
    m = m.toFixed(0);
    s = s.toFixed(1);

    h = '0'.repeat(2 - h.length) + h;
    m = '0'.repeat(2 - m.length) + m;
    s = '0'.repeat(4 - s.length) + s;

    return `${h} ${m} ${s}`;
  },

  /**
    * @param {float} dec decimal degrees
    */
  dec: (angle) => {
    const sign = (angle < 0) ? '-' : '+';
    let d = Math.floor(Math.abs(angle));
    let m = Math.floor((Math.abs(angle) - d) * 60);
    let s = ((Math.abs(angle) - d) * 60 - m) * 60;

    if (s >= 60) {
      s -= 60;
      m += 1;
    }

    if (m >= 60) {
      m -= 60;
      d += 1;
    }

    if (d >= 360) {
      d -= 360;
    }

    d = d.toFixed(0);
    m = m.toFixed(0);
    s = s.toFixed(1);

    d = sign + '0'.repeat(2 - d.length) + d;
    m = '0'.repeat(2 - m.length) + m;
    s = '0'.repeat(4 - s.length) + s;

    return `${d} ${m} ${s}`;

  }
}


function formatCsvCell(value) {
  if (typeof value === "string")
    return `"${value}"`;
  else
    return value;
}

/**
 * First item defines the table columns.
 */
export function useDownloadableTable(rows) {
  const [url, setURL] = React.useState(null);
  React.useEffect(() => {
    // https://javascript.info/blob
    if (rows && rows.length) {
      let csv = "";
      const columns = Object.keys(rows[0]);
      csv = csv.concat(columns.map((column) => `"${column}"`).join(','), "\n");
      csv = csv.concat(...rows.map((row) =>
        columns.map((column) =>
          formatCsvCell(row[column]))
          .join(',')
          .concat("\n")
      ));

      const blob = new Blob([csv], { type: 'text/csv' });
      setURL(URL.createObjectURL(blob));
      return () => {
        URL.revokeObjectURL(url);
      }
    }
  }, [rows]);
  return url;
}

export function stackNavigator(stacks, stackIndex, setStackIndex, selectedStackIndices) {
  const viewIndex = (index) => {
    if (!isFinite(index)) {
      setStackIndex(0);
    } else if (index < 0) {
      setStackIndex(stacks.length - index);
    } else if (index >= stacks.length) {
      setStackIndex(index - stacks.length);
    } else {
      setStackIndex(index);
    }
  }
  return {
    view: (stack) => viewIndex(stack.index),
    viewByStackPrefix: (stackPrefix) => viewIndex(stacks.find(row => row['stack prefix'] === stackPrefix).index),
    viewByStackBasename: (stackBasename) => viewIndex(stacks.find(row => row.basename === stackBasename).index),
    viewByStackIndex: (index) => viewIndex(index),
    first: () => viewIndex(selectedStackIndices.length ? selectedStackIndices[0] : 0),
    previous: () => {
      if (selectedStackIndices.length) {
        const current = selectedStackIndices.indexOf(stackIndex);
        const previous = (current === 0) ? (selectedStackIndices.length - 1) : (current - 1);
        viewIndex(selectedStackIndices[previous]);
      } else {
        const i = (stackIndex === 0) ? (stacks.length - 1) : (stackIndex - 1);
        viewIndex(i);
      }
    },
    next: () => {
      if (selectedStackIndices.length) {
        const current = selectedStackIndices.indexOf(stackIndex);
        const next = (current === (selectedStackIndices.length - 1)) ? 0 : (current + 1);
        viewIndex(selectedStackIndices[next]);
      } else {
        const i = (stackIndex === (stacks.length - 1)) ? 0 : (stackIndex + 1);
        viewIndex(i);
      }
    },
    last: () => viewIndex(
      selectedStackIndices.length
        ? selectedStackIndices[selectedStackIndices.length - 1]
        : (stacks.length - 1)
    )
  };
}

// const stackNavigation = {
//   view: (stack) => setStackIndex(stacks.index),
//   viewStackPrefix: (stackPrefix) => setStackIndex(stacks.find(row => row['stack prefix'] === stackPrefix).index),
//   first: () => setStackIndex(selectedStackIndices.length ? selectedStackIndices[0] : 0),
//   previous: () => {
//     if (selectedStackIndices.length) {
//       const current = selectedStackIndices.indexOf(stackIndex);
//       const previous = (current === 0) ? (selectedStackIndices.length - 1) : (current - 1);
//       setStackIndex(selectedStackIndices[previous]);
//     } else {
//       const i = (stackIndex === 0) ? (stacks.length - 1) : (stackIndex - 1);
//       setStackIndex(i);
//     }
//   },
//   next: () => {
//     if (selectedStackIndices.length) {
//       const current = selectedStackIndices.indexOf(stackIndex);
//       const next = (current === (selectedStackIndices.length - 1)) ? 0 : (current + 1);
//       setStackIndex(selectedStackIndices[next]);
//     } else {
//       const i = (stackIndex === (stacks.length - 1)) ? 0 : (stackIndex + 1);
//       setStackIndex(i);
//     }
//   },
//   last: () => setStackIndex(
//     selectedStackIndices.length
//       ? selectedStackIndices[selectedStackIndices.length - 1]
//       : (stacks.length - 1)
//   )
// };
